/* style.module.css */

.container {
    /* background-color: #f0f8ff; */
    margin-top: -30px;
    /* padding: 20px; */
    width: 100%;
    margin-bottom: 70px;
}

.header {
    background: linear-gradient(90deg, #64b5f6, #4fc3f7);
    color: white;
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* padding-top: 50px; */
    padding: 10px;
    font-size: 20px;
    border-radius: 10px;
    /* position: absolute;
    top: 10px;
    width: 90%; */
    /* left: 0; */
    margin-bottom: 20px;
    /* width: 100%; */
}

.part {
    font-size: 24px;
    font-weight: 700;
    color: #171a83;
}

.searchBar {
    position: relative;
    width: 100%;
}

.input {
    width: 100%;
    padding: 12px 16px;
    box-sizing: border-box;
    padding-right: 48px;
    border: 1px solid #E2E8F0;
    border-radius: 8px;
    background-color: white;
    font-size: 16px;
    transition: all 0.2s ease;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.searchIcon {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #64748B;
}

.resetButton {
    background-color: #ff7f50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 10px;
}

.resetButton:hover {
    background-color: #e66c45;
}

.orderList {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.orderItem {
    background-color: white;
    border-radius: 15px;
    padding: 16px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    width: 100%;
    flex: 1 1 calc(50% - 10px);
    /* две карточки в ряд */
    box-sizing: border-box;
}


.orderItem:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.orderItem strong {
    display: block;
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
}


.orderDetails {
    margin-top: 10px;
    font-size: 14px;
    color: #555;
}

.toggleButton {
    background-color: #4fc3f7;
    color: white;
    border: none;
    padding: 10px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 10px;
    width: 100%;
    font-weight: 600;
}

.toggleButton:hover {
    background-color: #39acdb;
}

.qrCodeContainer {
    margin-top: 10px;
    text-align: center;
}

.qrCodeImage {
    max-width: 100%;
    height: auto;
    border: 1px solid #ddd;
    padding: 5px;
    background-color: white;
}

.loader {
    text-align: center;
    font-size: 18px;
    color: #64b5f6;
}

.noResults {
    text-align: center;
    font-size: 18px;
    color: #aaa;
}

.noMore {
    text-align: center;
    font-size: 18px;
    color: #aaa;
}

.dataList {
    margin: 0;
}

.filterButtons {
    display: flex;
    gap: 8px;
    width: 100%;
}

.filterButton {
    flex: 1;
    background-color: #F8FAFC;
    color: #64748B;
    border: none;
    padding: 10px 16px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    border: 1px solid #E2E8F0;
}

.filterButton:hover {
    background-color: #F1F5F9;
}

.filterButton.active {
    background-color: #3B82F6;
    color: white;
    border-color: #3B82F6;
    box-shadow: 0 1px 2px rgba(59, 130, 246, 0.3);
}

.timer {
    background-color: #fff3e0;
    color: #e65100;
    font-weight: 600;
    padding: 10px;
    border-radius: 8px;
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
}

.orderSection {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 1.5;
    color: #444;
}


.label {
    font-weight: 500;
    color: #5a6a8a;
    margin-right: 4px;
    flex-shrink: 0;
}

.dotsSeparator {
    flex-grow: 1;
    border-bottom: 1px dotted #ccc;
    margin: 0 8px;
    height: 1px;
    align-self: flex-end;
    position: relative;
    top: 6px;
}

.row {
    display: flex;
    align-items: center;
    padding: 0px 0;
    font-size: 14px;
    position: relative;
}

.rowSeparator {
    height: 1px;
    background-image: radial-gradient(circle, #e0e0e0 1px, transparent 1px);
    background-size: 5px 1px;
    background-repeat: repeat-x;
    margin: 8px 0;
    border: none;
    opacity: 0.6;
}

.priceValue {
    font-weight: 600;
    color: #1e315a;
}

.orderTitle {
    font-size: 16px;
    font-weight: 700;
    color: #1e315a;
    margin-bottom: 12px;
    display: block;
}

.statusBadge {
    background-color: #e8f5e9;
    color: #2e7d32;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 500;
}

.trackNumber {
    font-family: monospace;
    color: #d32f2f;
    font-weight: 500;
}

.dotsSeparator {
    height: 1px;
    background-image: radial-gradient(circle, #ccc 1px, transparent 1px);
    background-size: 6px 2px;
    background-repeat: repeat-x;
    margin: 12px 0;
    border: none;
}


.payButton {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 12px;
    width: 100%;
    font-weight: 600;
    transition: background-color 0.3s;
}

.payButton:hover {
    background-color: #d32f2f;
}

.detailsButton {
    background-color: #1e315a;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 12px;
    width: 100%;
    font-weight: 600;
    transition: background-color 0.3s;
}

.detailsButton:hover {
    background-color: #142140;
}

.orderItem {
    transition: opacity 0.3s ease, transform 0.3s ease;
    opacity: 1;
    transform: scale(1);
}

.orderItem.removing {
    opacity: 0;
    transform: scale(0.95);
}

.autoCancelled {
    margin-top: 10px;
    padding: 8px;
    background-color: #fff3f3;
    color: #d32f2f;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
}