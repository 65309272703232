.appBar {
    position: fixed;
    /* display: block; */
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 500px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 12px 10px;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px 20px 0 0;
    backdrop-filter: blur(10px);
    z-index: 1000;
    /* background: linear-gradient(90.00deg, rgb(74, 203, 232), rgb(88, 224, 169) 100%); */
}

/* Контейнер кнопки */
.containerButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 12px;
    color: #323232;
    cursor: pointer;
    transition: color 0.3s ease, transform 0.2s ease;
    font-weight: 500;
}

.containerButton:hover {
    color: #007bff;
    transform: translateY(-3px);
}

.containerButton:active {
    transform: translateY(0);
}

/* Иконки */
.appBarButton {
    width: 40px;
    height: 40px;
    transition: transform 0.2s ease, opacity 0.2s ease;
}

.containerButton:hover .appBarButton {
    transform: scale(1.1);
}

/* Активная кнопка */
.active {
    color: #007bff;
    font-weight: bold;
}

.active .appBarButton {
    transform: scale(1.2);
    opacity: 1;
}