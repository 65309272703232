.orderDetails {
    margin-bottom: 30px;
    font-family: 'Segoe UI', Arial, sans-serif;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    gap: 20px;
}

.logo {
    max-width: 150px;
    height: auto;
}

.orderItem {
    background-color: white;
    border-radius: 15px;
    padding: 25px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.orderTitle {
    margin: 0 0 20px;
    text-align: center;
    font-size: 22px;
    color: #1e315a;
    font-weight: 600;
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-bottom: 25px;
}

.dataBlock {
    background: #f9fafc;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

.dataBlock h3 {
    font-size: 18px;
    margin: 0 0 15px;
    color: #1e315a;
    font-weight: 600;
    padding-bottom: 8px;
    border-bottom: 1px solid #eaeef5;
}

.infoRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
}

.infoRow span {
    color: #5a6a8a;
    font-weight: 500;
    font-size: 14px;
}

.infoRow p {
    margin: 0;
    color: #282828;
    font-size: 15px;
    text-align: right;
    word-break: break-word;
}

.status {
    color: #2e7d32 !important;
    font-weight: 500;
}

.price {
    color: #1e315a !important;
    font-weight: 600;
}

.trackNumber {
    font-family: monospace;
    color: #d32f2f !important;
}

.codesSection {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    margin-top: 30px;
}

.barcodeBlock,
.qrcodeBlock {
    text-align: center;
    background: #f9fafc;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
    flex: 1;
    min-width: 250px;
}

.barcodeBlock h3,
.qrcodeBlock h3 {
    margin: 0 0 15px;
    font-size: 16px;
    color: #1e315a;
}

.archiveButton {
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 500;
}

.archiveBtnColorRed {
    background-color: #d32f2f;
}

.archiveBtnColorBlue {
    background-color: #1e315a;
}

.archiveBtnColorBlue:hover {
    background-color: #142140;
    transform: translateY(-1px);
}

.archiveBtnColorRed:hover {
    background-color: #b71c1c;
    transform: translateY(-1px);
}