.container {
    /* background: url('../../assets/image/background.png') no-repeat center center; */
    background-size: cover;
    background: linear-gradient(88.28deg, rgb(71, 199, 245) 3.081%, rgb(88, 224, 169) 98.091%);
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.decorBackground1 {
    position: absolute;
    width: 130px;
    top: -55px;
    left: -50px;
}

.decorBackground2 {
    position: absolute;
    height: 230px;
    top: 85px;
    right: 0;
    z-index: 1;
    /* max-width: calc(100vw - 360px); */
}


.logoImg {
    margin: 30px 0;
    width: 250px;
    height: auto;
    cursor: pointer;
}

/* Контейнер progress bar */
.progressBarContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: #f3f3f3;
    z-index: 9999;
}

.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40vh;
}

/* Сам progress bar */
.progressBar {
    height: 100%;
    overflow: hidden;
}

/* Заполняющая часть progress bar */
.progressFill {
    height: 100%;
    background-color: #4caf50;
    width: 0%;
    transition: width 0.3s ease-in-out;
}

.menuContainer {
    box-sizing: border-box;
    padding: 50px 20px;
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: calc(100vh - 180px);
    background: rgba(240, 240, 240, 0.8);
    border-radius: 40px 40px 0 0;
    font-size: 18px;
    gap: 25px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    flex: 1 1 auto;
    z-index: 2;
}

.buttonPointer {
    box-shadow: 0 4px 10px rgba(78, 78, 78, 0.16);
    background: rgb(29, 131, 252);
    width: 80%;
    height: 70px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    justify-content: start;
    gap: 30px;
    color: white;
    border-radius: 15px;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    font-weight: 500;
}

.buttonPointer:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 15px rgba(78, 78, 78, 0.2);
}

.button {
    box-shadow: 0 4px 10px rgba(78, 78, 78, 0.16);
    background: white;
    width: 80%;
    height: 70px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    justify-content: start;
    gap: 30px;
    color: black;
    border-radius: 15px;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    font-size: 16px;
    font-weight: 500;
}

.button:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 15px rgba(78, 78, 78, 0.2);
}

.loadingText {
    font-size: 20px;
    font-weight: bold;
    color: #007BFF;
    text-align: center;
    margin-top: 20px;
}

.errorText {
    font-size: 20px;
    font-weight: bold;
    color: red;
    text-align: center;
    margin-top: 20px;
}

.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    /* Расстояние между кнопками */
    width: 100%;
    /* height: 100%; */

}

/* Стиль для кнопки Telegram */
.telegramButton {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: #ffffff;
    background-color: #0088cc;
    /* Цвет Telegram */
    border: none;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.telegramButton:hover {
    background-color: #0077b3;
    /* Темнее цвет при наведении */
    transform: scale(1.05);
    /* Масштабирование при наведении */
}

.telegramButton:active {
    transform: scale(0.95);
    /* Сжатие при клике */
}

.errorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Анимация для текста загрузки */
.loadingAnimation {
    display: inline-block;
    animation: pulse 1.5s infinite;
}

/* App.css */
@keyframes dots-bounce {

    0%,
    80%,
    100% {
        transform: scale(0);
    }

    40% {
        transform: scale(1);
    }
}

.loadingDots {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.dot {
    width: 15px;
    height: 15px;
    background-color: #36d7b7;
    border-radius: 50%;
    margin: 0 5px;
    animation: dots-bounce 1.4s infinite ease-in-out;
}

.dot:nth-child(1) {
    animation-delay: -0.32s;
}

.dot:nth-child(2) {
    animation-delay: -0.16s;
}

/* Ключевые кадры для анимации пульсации */
@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1.1);
        opacity: 0.8;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}